@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-base min-w-[320px] min-h-screen bg-white;
  }

  #root {
    @apply flex flex-col justify-between min-h-screen;
  }

  .container {
    @apply px-2 sm:px-5;
  }
}

@layer components {
  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
  }

  h1, .text-h1 {
    @apply text-md sm:text-lg md:text-xl lg:text-xxl;
  }

  h2, .text-h2 {
    @apply text-lg lg:text-xl;
  }

  h3, .text-h3 {
    @apply text-md lg:text-lg;
  }

  h4, .text-h4 {
    @apply text-sm lg:text-md;
  }

  input,
  textarea {
    @apply transition-all;
    /*@apply relative block w-full appearance-none border border-2 rounded-md py-2 px-2 text-gray-900 placeholder-gray-500*/
    /*focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm*/
  }

  .text-editor a:link {
    @apply underline text-primary;
  }
}

@layer utilities {
  .lock-scrollbar {
    overflow: hidden;
    overflow-y: scroll !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .webkit-overflow-scroll {
    -webkit-overflow-scrolling: touch;
  }

  .text-line-clamp-3 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  .text-line-clamp-3 {
    -webkit-line-clamp: 3;
  }
}
